<template>
    <fvMain>
        <div v-if="blog" class="blog-container">
            <img v-if="blog.Image" :src="`${this.sdk_url}${blog.Image.path}`" class="responsive-img" />
            <h1 class="title">{{blog.Story_Name}}</h1>
            <h5 class="subTitle">-by {{blog.Author_Name}}</h5>
            <p class="desc">{{blog.Desc}}</p>
        </div>
        <div v-else>
            <h1 class="title">Blog Not Found</h1>
        </div>
    </fvMain>
</template>
<script>
    export default {
        data() {
            return {
                blog: [],
                sdk_url:'https://bijit.xyz'
            }
        },
        methods: {
            FetchBlog(id) {
                if(localStorage.getItem('authenticated')){
                    const options = {
                        method: 'POST',
                        headers: { 'content-type': 'application/json' },
                    };

                    fetch(`${this.sdk_url}/cockpit-master/api/collections/get/Blog?token=738ebf1f7e4c7b976cdfd51ee8b84d&filter%5B_id%5D=${id}`, options)
                        .then(response => response.json())
                        .then(response => {
                            this.blog = response.entries[0];
                        })
                    .catch(err => console.error(err));
                }
            }
        },
        created() {
            this.FetchBlog(this.$route.params.id);
        }
    }
</script>
<style>
    .blog-container {
        width: 100%;
    }
    .responsive-img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .desc {
        margin: 1rem;
        text-align: justify;
    }
    @media (min-width: 768px) {
        .responsive-img {
            width: 30%;
            height: 47%;
            object-fit:contain;
            margin-top: 20px;
        }
        .desc{
            padding-left: 80px;
            padding-right: 80px;
        }
    }
    .title {
        font-size: 2rem;
        text-align: center;
        margin-top: 1rem;
    }
    .subTitle{
        font-size: 1rem;
        text-align: center;
        font-weight: 100;
        font-style: italic;
    }
</style>